@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.container {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  color: #2c3e50;
  background-color: #ecf0f1;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  max-width: 800px;
  margin: 0 auto;
}

.content > div {
  width: 100%;
  margin: 0;
  text-align: left;
  line-height: 1.5;
}

.link {
  color: #2980b9;
  text-decoration: none;
  transition: color 0.2s ease;
  position: relative;
}

.link:hover {
  color: #1abc9c;
}

.link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: currentColor;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.link:hover::after {
  transform: scaleX(1);
}

@media (max-width: 768px) {
  .container {
    padding: 1.5rem;
    font-size: 1rem;
  }
}

.socialLinks {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 20px;
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 50%;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease;
}

.socialLink:hover {
  transform: translateY(-2px);
}

.socialLink svg {
  width: 20px;
  height: 20px;
  display: block;
}

@media (max-width: 400px) {
  .socialLinks {
    gap: 0.8rem;
  }
}

.imageSlider {
  display: flex;
  overflow-x: auto;
  gap: 16px;
  padding: 16px 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.imageSlider::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.sliderImage {
  height: 200px;
  width: auto;
  border-radius: 8px;
  flex-shrink: 0;
}

.sliderContainer {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  margin-top: 2rem;
  overflow-x: hidden;
  position: relative;
}

.sliderContainer::-webkit-scrollbar {
  display: none;
}

.sliderContainer {
  -ms-overflow-style: none;
}

.sliderImage {
  max-width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
}

.sliderTrack {
  display: flex;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 20px;
  width: max-content;
}

@media (max-width: 768px) {
  .sliderContainer {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .sliderTrack {
    overflow-x: scroll;
    touch-action: pan-x pan-y;
    -webkit-overflow-scrolling: touch;
    user-select: none;
    -webkit-user-select: none;
  }
}

.sliderTrack::-webkit-scrollbar {
  display: none;
}

.sliderTrack:active {
  cursor: grabbing;
}

.sliderImage {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  margin-right: 20px;
  object-fit: cover;
}

.sliderImage:last-child {
  margin-right: 0;
}

.sliderButton {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Only show arrows on devices with hover capability (desktop/laptop) */
@media (hover: hover) {
  .sliderButton {
    display: flex;
  }
  
  .sliderButton::before {
    content: '';
    width: 12px;
    height: 12px;
    border-right: 2px solid #2c3e50;
    border-bottom: 2px solid #2c3e50;
    transition: all 0.3s ease;
  }

  .prevButton::before {
    transform: rotate(135deg);
    margin-left: 4px;
  }

  .nextButton::before {
    transform: rotate(-45deg);
    margin-right: 4px;
  }

  .sliderButton:hover {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-50%) scale(1.05);
  }

  .sliderButton:hover::before {
    border-color: #1abc9c;
  }
}

.prevButton {
  left: 20px;
}

.nextButton {
  right: 20px;
}

/* Optional: Hide buttons on mobile */
@media (max-width: 768px) {
  .sliderButton {
    display: none;
  }
}

.divider {
  width: 100%;
  text-align: center;
  margin: 2rem 0;
}

.spacer {
  width: 100%;
  height: 2rem;
}

.navbar {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.navButton {
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.navButton:hover {
  background-color: #f0f0f0;
}

.topNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 1000;
  height: 64px;
}

/* Make navbar solid on mobile only */
@media (max-width: 768px) {
  .topNav {
    background: #ecf0f1 !important;
    background-color: #ecf0f1 !important;
    opacity: 1 !important;
  }
}

.navContent {
  width: 100%;
  max-width: 600px;  /* Match your content width */
  margin: 0 auto;
  padding: 1rem 20px;
  display: flex;
  justify-content: space-between;  /* This spreads the links to opposite sides */
}

.navLinks {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;  /* Space between the links */
}

.selected {
  opacity: 1;
  pointer-events: none;  /* Prevents clicking on the current page link */
  text-decoration: underline;  /* or any other style to indicate selection */
}

.navLink {
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

.selected {
  color: #2980b9;
  pointer-events: none;
}
