@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.header {
  font-family: 'EB Garamond', serif;
}

.divider {
  height: .5px;
  background-color: #81ffff;
}

.title {
  font-size: 3rem;
  margin: 20px 0;
  text-align: center;
}

@media (min-width: 769px) {
  .title {
    font-size: 6rem;
  }
}
