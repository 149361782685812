@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  padding-top: 64px;
  background-color: #ecf0f1;
  width: 100%;
  max-width: 1200px;
}

.navbar {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.navButton {
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.navButton:hover {
  background-color: #f0f0f0;
}

.photoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.gridImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 4px;
}

body {
    background-color: #ecf0f1;
  }

.topNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #ecf0f1;
  background-color: #ecf0f1;
  opacity: 1 !important;
  z-index: 1000;

  -webkit-backdrop-filter: none !important;
  backdrop-filter: none !important;
}

.topNav::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ecf0f1;
  z-index: -1;
}

.navContent {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 1.25rem 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.navTitle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.5rem;
    font-family: "Crimson Text", "Merriweather", serif;
    color: inherit;
    font-weight: 40;
    letter-spacing: 0.02em;  /* Add slight letter spacing to match */
    color: #2c3e50;
  }

.link {
 color: #2980b9;
  text-decoration: none;
  transition: opacity 0.2s ease;
}

.link:hover {
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: #1abc9c;
}

.navLink {
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

.selected {
  opacity: 1;
  pointer-events: none;
}

.navButton {
    padding: 8px 16px;
    border: 1px solid #000;
    border-radius: 4px;
    text-decoration: none;
    color: #000;
    font-size: 14px;
  }
  
  .navButton:hover {
    background-color: #ecf0f1;
  }
  
  .topNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .navContent {
    width: 100%;
    max-width: 600px;  /* Match your content width */
    margin: 0 auto;
    padding: 1rem 20px;
    display: flex;
    justify-content: space-between;  /* This spreads the links to opposite sides */
  }
  
  .navLinks {
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;  /* Space between the links */
  }
  
  .selected {
    opacity: 1;
    pointer-events: none;  /* Prevents clicking on the current page link */
    text-decoration: underline;  /* or any other style to indicate selection */
  }
  
  .navLink {
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
  }
  
  .selected {
    color: #2980b9;
    pointer-events: none;
  }
  
.myMasonryGrid {
  display: flex;
  width: auto;
  margin-left: -8px;
  padding: 20px;
}

.myMasonryGridColumn {
  padding-left: 8px;
  background-clip: padding-box;
}

.gridImage {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 8px;
  border-radius: 4px;
  object-fit: contain;
}
  